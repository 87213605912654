import { Stack, styled } from '@mui/material';

// --------------------------------------------------------------

export const StyledContainer = styled(Stack)(() => ({
  position: 'fixed',
  bottom: '20px',
  left: '50%',
  transform: 'translateX(-50%)',
  backgroundColor: '#fff',
  display: 'flex',
  flexDirection: 'row',
  padding: '20px',
  gap: '20px',
  maxWidth: '600px',
  borderRadius: '8px',
  boxShadow: '0 2px 15px rgba(0, 0, 0, 0.3)',
  zIndex: 1300,
  width: '100%',

  '@media (max-width: 150px)': {
    width: '90%',
    transform: 'translateX(-45%)',
    padding: '10px',
    flexDirection: 'column',
    gap: '10px',
  },

  '@media (max-width: 750px)': {
    width: '90%',
    transform: 'translateX(-50%)',
    flexDirection: 'column',
    padding: '15px',
    gap: '10px',
  },
}));
