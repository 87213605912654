import { ListItemButton, Paper } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { COLOR } from 'src/theme/custom/colors';
import { NavItemDesktopProps } from '../types';

// -----------------------------------------------------------------------------------------

type ListItemProps = Omit<NavItemDesktopProps, 'item'>;

export const ListItem = styled(ListItemButton, {
  shouldForwardProp: (prop) =>
    prop !== 'active' && prop !== 'open' && prop !== 'isOffset' && prop !== 'subItem',
})<ListItemProps>(({ active, open, isOffset, subItem, theme }) => ({
  ...theme.typography.subtitle1,
  padding: 0,
  overflow: 'hidden',
  height: '67%',
  color: 'white',
  transition: theme.transitions.create(['opacity', 'color'], {
    duration: theme.transitions.duration.shorter,
  }),
  '&:hover': {
    opacity: 0.48,
    backgroundColor: 'transparent',
  },
  ...(subItem && {
    ...theme.typography.subtitle1,
    color: theme.palette.text.primary,
  }),
  ...(isOffset && {
    color: theme.palette.text.primary,
  }),
  ...(active && {
    color: COLOR.orange,
    ...theme.typography.subtitle1,
  }),
  ...(active &&
    subItem && {
      ...theme.typography.subtitle2,
      color: theme.palette.primary.main,
      '&::before': {
        color: theme.palette.primary.main,
      },
    }),
  ...(open && {
    color: theme.palette.primary.main,
  }),
}));

// ----------------------------------------------------------------------

export const StyledMenu = styled(Paper)(({ theme }) => ({
  width: '320px',
  display: 'flex',
  position: 'absolute',
  top: '100%',
  left: 0,
  transform: 'translateX(-60%)',
  textAlign: 'justify',
  padding: theme.spacing(3, 3),
  boxShadow: theme.customShadows.dialog,
  borderRadius: Number(theme.shape.borderRadius) * 2,
  border: `solid 1px ${alpha(theme.palette.grey[500], 0.16)}`,
  zIndex: 9999,
}));
// ----------------------------------------------------------------------
