import { Button, Stack, Typography } from '@mui/material';
import SocialMedia from 'src/features/components/socialMedia';
import { socialMediaContent } from './content';

// ----------------------------------------------------------------------------

export default function Social() {
  const light = '#FFF';

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <>
      <Stack
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'row',
          width: '100%',
          marginBottom: '30px',
        }}
      >
        <Stack
          id="contact"
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Typography gutterBottom variant="h5" mt={5} color={light}>
            Contato
          </Typography>

          <Typography gutterBottom variant="body1" color={light} width={600} align="center">
            Telefone: (69) 99252-5479
          </Typography>
          <Typography gutterBottom variant="body1" color={light} width={600} align="center">
            E-mail: gerencia@fullcodedev.com
          </Typography>
        </Stack>

        <Stack
          id="contact"
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Button variant="contained" onClick={handleScrollToTop} fullWidth>
            Voltar ao Topo
          </Button>
        </Stack>

        <Stack
          id="social-media"
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Typography gutterBottom variant="h5" component="div" align="center" color={light}>
            Redes Sociais
          </Typography>

          <SocialMedia content={socialMediaContent} />
        </Stack>

        {/* <Stack
          id="contact"
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Typography gutterBottom variant="h5" mt={5} color={light}>
            Endereço
          </Typography>

          <Typography gutterBottom variant="body1" color={light} width={600} align="center">
            Rua Presidente Médice, Nº 113, <br />
            Centro, Vilhena-RO, CEP: 76980-096
          </Typography>
        </Stack> */}
      </Stack>
    </>
  );
}
