import { Box, Button, Card, CardContent, Chip, Stack, Typography, useTheme } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { RegistrationPixels } from 'src/config/facebookPexelEvent';
import { StyledContainer, StyledImage } from './styles';

// ---------------------------------------------------------------------

export interface CourseCardProps {
  src: string;
  alt: string;
  title: string;
  buttonTitle: string;
  link?: string;
  description: string;
  topics: string[];
  recorded?: boolean;
  live?: boolean;
  liveDescription?: string;
  value?: string;
  installments?: string;
  valueInstallments?: string;
  totalHours?: string;
  disableCount?: boolean;
}

// ---------------------------------------------------------------------

export default function CourseCard({
  src,
  alt,
  title,
  buttonTitle,
  link,
  description,
  topics,
  recorded,
  live,
  liveDescription,
  value,
  installments,
  valueInstallments,
  totalHours,
  disableCount,
}: CourseCardProps) {
  const theme = useTheme();

  const handleButtonClick = () => {
    const formSubscribe = valueInstallments || '0';
    RegistrationPixels(formSubscribe);
  };

  return (
    <Card style={{ marginBottom: '20px' }}>
      <StyledContainer bgcolor={theme.palette.background.paper}>
        <StyledImage src={src} alt={alt} />

        <CardContent>
          <Stack spacing={2}>
            <Stack
              direction="row"
              spacing={2}
              flexWrap="wrap"
              color={theme.palette.text.primary}
              justifyContent={{ xs: 'center', sm: 'flex-start' }}
            >
              <Typography variant="h4">{title}</Typography>
              {recorded && <Chip label="Aulas Gravadas" color="default" />}
              {live && <Chip label="Presencial por Videoconferência" color="default" />}
            </Stack>

            {!disableCount && (
              <Stack direction="row" spacing={5} mt={3}>
                <Stack>
                  <Typography
                    variant="h3"
                    color={theme.palette.primary.main}
                    alignContent="flex-end"
                  >
                    {installments}x R$ {valueInstallments}
                  </Typography>

                  <Typography variant="h6">ou R$ {value} à vista. </Typography>
                  <Typography variant="h6">Pagamento feito via boleto bancário</Typography>
                </Stack>

                <Stack alignSelf="flex-end">
                  <Typography variant="h3" color={theme.palette.primary.main}>
                    {totalHours}h
                  </Typography>
                  <Typography variant="h6">Duração</Typography>
                  <Typography variant="h6">Contato direto com o professor</Typography>
                </Stack>
              </Stack>
            )}

            <Typography
              variant="body1"
              component="div"
              textAlign="justify"
              dangerouslySetInnerHTML={{ __html: description }}
            />

            <Typography variant="h6" gutterBottom mt={2} textAlign="left">
              O que você irá aprender neste curso:
            </Typography>

            <br />
            {topics.map((item, index) => (
              <React.Fragment key={index}>
                {item}
                <br />
              </React.Fragment>
            ))}

            {liveDescription && (
              <Box width="100%" height="5px" bgcolor="#FEA550" my={2} borderRadius={10} />
            )}

            {liveDescription && (
              <Typography
                variant="h6"
                component="div"
                align="center"
                color="red"
                dangerouslySetInnerHTML={{ __html: liveDescription }}
              />
            )}

            <Box width="100%" height="5px" bgcolor="#FEA550" my={2} borderRadius={10} />

            <Link to={link || '#'}>
              <Button
                variant="contained"
                fullWidth
                onClick={handleButtonClick}
                sx={{
                  fontSize: '1rem',
                  padding: '12px 24px',
                  textTransform: 'none',
                }}
              >
                {buttonTitle}
              </Button>
            </Link>
          </Stack>
        </CardContent>
      </StyledContainer>
    </Card>
  );
}
