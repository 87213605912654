import { CircularProgress } from '@mui/material';
import { CenterizedCompContainer } from 'src/features/global';

// -------------------------------------------------------------

export default function CustomLoadingScreen() {
  return (
    <>
      <CenterizedCompContainer height="100vh" width="100%" flexDirection="column" gap={5}>
        <CircularProgress />
        <img src="/logo/light.png" alt="Logo Full Code" width={300} />
      </CenterizedCompContainer>
    </>
  );
}
