import { Box, Button, ButtonGroup, Stack, styled } from '@mui/material';
import { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import ScrollProgress from 'src/components/scroll-progress';
import { LeadPixels } from 'src/config/facebookPexelEvent';
import CourseCard from 'src/features/components/courseCard';
import CoverImage from 'src/features/components/coverImage';
import WhatsAppPopup from 'src/features/components/whatsAppPopup';
import { CompanyName, TextContainer } from 'src/features/global';
import { PATH_COURSES, PATH_PAGE } from 'src/routes/paths';
import { coursesData } from './coursesArr';
// -------------------------------------------------------------------------------------------------

export const StyledBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: '30px',

  '@media (max-width: 750px)': {
    width: '100%',
  },
}));

export const StyledButtonGroup = styled(Stack)(() => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'center',
  marginTop: '40px',
}));

export const StyledContainerCourse = styled(Stack)(() => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'center',
}));

// -------------------------------------------------------------------------------------------------

export default function CursoOnline() {
  const [activeButton, setActiveButton] = useState('Gratuitos');

  const titlePage = 'Cursos Online';

  const handleButtonClick = (buttonName: string) => {
    setActiveButton(buttonName);
  };

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
    LeadPixels();
  }, []);
  return (
    <>
      <Helmet>
        <title>
          {titlePage} | {CompanyName}
        </title>
      </Helmet>

      <CoverImage
        title={titlePage}
        links={[{ name: 'Inicio', href: PATH_PAGE.home }, { name: titlePage }]}
        redirect={[
          { variant: 'contained', path: PATH_COURSES.aovivo, title: 'Ver Cursos AO VIVO' },
        ]}
      />

      <StyledBox>
        <ScrollProgress />

        <TextContainer>
          <Stack width="100%" gap={2} mb={5} padding={{ xs: '20px', sm: '0px' }}>
            <StyledButtonGroup>
              <ButtonGroup variant="contained">
                {Object.keys(coursesData).map((category) => (
                  <Button
                    key={category}
                    variant={activeButton === category ? 'contained' : 'soft'}
                    onClick={() => handleButtonClick(category)}
                  >
                    {category}
                  </Button>
                ))}
              </ButtonGroup>
            </StyledButtonGroup>

            <StyledContainerCourse>
              {activeButton &&
                coursesData[activeButton as keyof typeof coursesData].map((course: any) => (
                  <CourseCard
                    src={course.src}
                    alt={course.alt}
                    title={course.title}
                    buttonTitle={course.buttonTitle}
                    link={course.link}
                    description={course.description}
                    topics={course.topics}
                    live={course.live}
                    recorded={course.recorded}
                    liveDescription={course.liveDescription}
                    value={course.value}
                    installments={course.installments}
                    valueInstallments={course.valueInstallments}
                    totalHours={course.totalHours}
                    disableCount={course.disableCount}
                  />
                ))}
            </StyledContainerCourse>
          </Stack>
        </TextContainer>

        <WhatsAppPopup />
      </StyledBox>
    </>
  );
}
