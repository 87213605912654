import { CourseDataProps } from '../cursoAoVivo/coursesArr';

// ------------------------------------------------------------------------

export const coursesData: CourseDataProps = {
  Gratuitos: [
    {
      src: '/images/cursos/html5.png',
      alt: 'Capa curso HMTL5',
      title: 'HTML5 do Zero ao Avançado',
      buttonTitle: 'Estudar agora',
      link: '/embreve',
      recorded: true,
      disableCount: true,
      description: `
      O HTML5 é fundamental para a estruturação de sites e sistemas web. Sem ele,
      navegadores não conseguem interpretar e exibir o conteúdo corretamente, o que torna
      seu domínio indispensável para a criação de projetos web funcionais. Ao final deste
      curso, você terá as habilidades necessárias para construir sites e sistemas web
      completos, com uma estrutura robusta e adequada para diversas necessidades e estará
      apto para a próxima etapa: o CSS, onde vamos adicionar beleza e personalização ao
      nosso site.
      `,
      topics: [
        'História e evolução do HTML5;',
        'Preparação de ambiente com HTML5;',
        'Estruturação e tageamento;',
        'Manupulação de textos e parágrafos;',
        'Manipulação de Links, Imagens, Tabelas, Fomulários, Multimídia, Iframes e',
        'muito mais.',
      ],
    },
  ],
  'Front-End': [
    {
      src: '/images/cursos/frontend.png',
      alt: 'Capa curso Front-End',
      title: 'Front-End do Básico ao Domínio',
      buttonTitle: 'Inscreva-se',
      link: '/embreve',
      recorded: true,
      live: true,
      disableCount: true,
      description: `
      Este curso foi cuidadosamente projetado para ensinar tudo o que você precisa saber
      para construir uma base sólida no desenvolvimento web. Você aprenderá desde os
      fundamentos até técnicas avançadas para criar sites, sistemas e aplicativos web
      modernos e eficientes. 
      </br>
      </br>
      Sem essa base, é difícil avançar de forma consistente na sua jornada como
      programador. Ao concluir o curso, você terá o conhecimento necessário para evoluir
      em qualquer área do desenvolvimento front-end, construindo interfaces atraentes,
      dinâmicas e funcionais.
      `,
      topics: [
        'HTML5 do zero ao domínio;',
        'Conceitos Básicos de Git;',
        'CSS3 do zero ao domínio;',
        'Lógica de programação e',
        'Javascript do zero ao domínio.',
      ],
    },
    {
      src: '/images/cursos/css3.png',
      alt: 'Capa curso CSS3',
      title: 'CSS3 do Zero ao Avançado',
      buttonTitle: 'Inscreva-se',
      link: '/embreve',
      recorded: true,
      disableCount: true,
      description: `
      O CSS3 é fundamental para a estética de um site. Sem ele, um site pode parecer sem
      vida, monótono e difícil de usar. Ao final deste curso, você dominará completamente
      o CSS, permitindo criar sites responsivos, coloridos, bem organizados e animados.
      `,
      topics: [
        'História e evolução do CSS3;',
        'Estrutura, sintaxe e seletores do CSS3;',
        'Manipulação de cores, icones, imagens, grids, lista e tabelas;',
        'Implementação e manipulação de fontes de texto;',
        'Alinhamento, grid layout e flex-box;',
        'Estilização de divs;',
        'CSS 2D e 3D;',
        'Animações e muito mais.',
      ],
    },
    {
      src: '/images/cursos/javascript.png',
      alt: 'Capa curso Javascript',
      title: 'Javascript do Zero ao Avançado',
      buttonTitle: 'Inscreva-se',
      disableCount: true,
      link: '/embreve',
      recorded: true,
      description: `
      O JavaScript é essencial para a interatividade e dinâmica de sites e sistemas web.
      Sem ele, a experiência do usuário seria limitada a páginas estáticas, tornando seu
      domínio crucial para criar projetos web dinâmicos e responsivos. Ao final deste
      curso, você terá as habilidades necessárias para implementar funcionalidades
      avançadas, manipular elementos da página em tempo real e integrar recursos
      interativos
      `,
      topics: [
        'História e evolução do JavaScript;',
        'Estrutura básica, sintaxe e operadores do JavaScript;',
        'Manipulação de elementos do DOM e eventos;',
        'Funções, objetos e arrays;',
        'Controle de fluxo e estruturas de decisão;',
        'Manipulação de dados e chamadas a APIs;',
        'Programação assíncrona com Promises e async/await;',
        'Conceitos avançados como closures e protótipos e Muito Mais.',
      ],
    },
  ],
};
