import { PATH_ARTICLES, PATH_COURSES, PATH_PAGE } from 'src/routes/paths';
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

const navConfig = [
  {
    title: 'Home',
    icon: <Iconify icon="tabler:home-filled" />,
    path: PATH_PAGE.home,
  },
  {
    title: 'Cursos',
    icon: <Iconify icon="ph:target-bold" />,
    path: '#',
    children: [
      {
        subheader: '',
        items: [
          { title: 'Online', path: PATH_COURSES.online },
          { title: 'Ao Vivo (Exclusivo)', path: PATH_COURSES.aovivo },
        ],
      },
    ],
  },

  {
    title: 'Artigos',
    icon: <Iconify icon="ooui:articles-ltr" />,
    path: '#',
    children: [
      {
        subheader: '',
        items: [
          { title: 'O que é Front-End', path: PATH_ARTICLES.frontend },
          { title: 'O que é Back-end', path: PATH_ARTICLES.backend },
          { title: 'O que é DevOps', path: PATH_ARTICLES.devops },
        ],
      },
    ],
  },
  {
    title: 'Sobre Nós',
    icon: <Iconify icon="mdi:about" />,
    path: PATH_PAGE.sobreNos,
  },
  {
    title: 'Trabalhe Conosco',
    icon: <Iconify icon="material-symbols:work" />,
    path: PATH_PAGE.trabalheConosco,
  },
];

export default navConfig;
