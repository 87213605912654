import { Stack, styled } from '@mui/material';

interface Props {
  width?: string;
}

export const Animate = styled(Stack)<Props>(({ width }: Props) => ({
  width,
  transformOrigin: '50% 50%',
  transition: 'transform 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.1)',
  },
}));

export const AnimateUp = styled(Stack)<Props>(({ width }: Props) => ({
  width,
  transformOrigin: '50% 50%',
  transition: 'transform 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-30px)', // Eleva 30px para cima
  },
}));

export const AnimateLeft = styled(Stack)<Props>(({ width }: Props) => ({
  width,
  transformOrigin: '50% 50%',
  transition: 'transform 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateX(-30px)', // Eleva 30px para cima
  },
}));
