import { useEffect, useState } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { StyledContainer } from './styles';

// ---------------------------------------------------------------------------------

export default function CookiesAdvice() {
  const [showCookieAdvice, setShowCookieAdvice] = useState(true);

  useEffect(() => {
    const isCookiesAccepted = localStorage.getItem('cookiesAccepted');
    if (isCookiesAccepted) {
      setShowCookieAdvice(false);
    }
  }, []);

  const handleAcceptCookies = () => {
    localStorage.setItem('cookiesAccepted', 'true');
    setShowCookieAdvice(false);
  };

  if (!showCookieAdvice) {
    return null;
  }

  return (
    <StyledContainer>
      <Typography
        variant="subtitle1"
        align="justify"
        sx={{
          sm: { textAlign: 'initial' },
          xs: { textAlign: 'justify' },
          padding: 2,
        }}
      >
        Este site utiliza cookies para fornecer uma melhor experiência de navegação, analisar como
        você interage com nosso site, otimizar o desempenho e personalizar o conteúdo. Ao utilizar
        este site, você concorda com o uso de cookies.
      </Typography>

      <Button variant="contained" onClick={handleAcceptCookies}>
        OK
      </Button>
    </StyledContainer>
  );
}
