import { ElementType, Suspense, lazy } from 'react';
import CustomLoadingScreen from 'src/features/components/loadingScreen';

// ------------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) =>
  (
    <Suspense fallback={<CustomLoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// ------------------------------------------------------------------------

export const SobreNos = Loadable(lazy(() => import('../features/pages/about-us/index')));

export const DesenvolvimentoSoftware = Loadable(
  lazy(() => import('../features/pages/solucoes/desenvolvimentoPage/index'))
);

export const FrontEnd = Loadable(
  lazy(() => import('../features/pages/desenvolvimento/frontend/index'))
);

export const Backend = Loadable(
  lazy(() => import('../features/pages/desenvolvimento/backend/index'))
);

export const DevOps = Loadable(lazy(() => import('../features/pages/desenvolvimento/devops')));

export const MarketingDigital = Loadable(
  lazy(() => import('../features/pages/solucoes/marketingPage/index'))
);

export const SistemasEmpresariais = Loadable(
  lazy(() => import('../features/pages/solucoes/sistemasPage/index'))
);

export const Cursos = Loadable(lazy(() => import('../features/pages/solucoes/cursos/index')));

export const CursosAoVivo = Loadable(
  lazy(() => import('../features/pages/cursos/cursoAoVivo/index'))
);

export const Page404 = Loadable(lazy(() => import('../pages/Page404')));

export const HomePage = Loadable(lazy(() => import('../features/pages/home')));

export const ComingSoonPage = Loadable(lazy(() => import('../pages/ComingSoonPage')));

export const MaintenancePage = Loadable(lazy(() => import('../pages/MaintenancePage')));

export const AboutUs = Loadable(lazy(() => import('../features/pages/about-us/index')));

export const Documentos = Loadable(lazy(() => import('../features/pages/documentacao/index')));

export const ContactUs = Loadable(lazy(() => import('../features/pages/contact-us/index')));

export const WorkUs = Loadable(lazy(() => import('../features/pages/work-us/index')));
