import { Button, Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import { COLOR } from 'src/theme/custom/colors';
import { StyledCover } from './styles';

// ---------------------------------------------------------------------

interface CoverProps {
  title: string;
  subtitle?: string;
  redirect?: {
    path?: string;
    variant?: string;
    title?: string;
  }[];
  links: {
    name: string;
    href?: string;
  }[];
}

// ---------------------------------------------------------------------

export default function CoverWithoutImage({ title, subtitle, links, redirect }: CoverProps) {
  return (
    <StyledCover>
      <Stack display="flex" justifyContent="center" mt={10}>
        <Typography variant="h3" align="center" color="white">
          {title}
        </Typography>
      </Stack>

      <Typography variant="h5" align="center" color={COLOR.orange}>
        {subtitle}
      </Typography>

      <Stack mt={5}>
        <CustomBreadcrumbs links={links} cover />
      </Stack>

      {redirect &&
        redirect.map((data: any) => (
          <Stack textAlign="center">
            <Link to={data.path}>
              <Button variant={data.variant}>{data.title}</Button>
            </Link>
          </Stack>
        ))}
    </StyledCover>
  );
}
