import { Fade, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useActiveLink from '../../../../hooks/useActiveLink';
import { NavItemProps } from '../types';
import { NavItem } from './NavItem';
import { StyledMenu } from './styles';

// ----------------------------------------------------------------------

type NavListProps = {
  item: NavItemProps;
  isOffset: boolean;
};

// ----------------------------------------------------------------------

export default function NavList({ item, isOffset }: NavListProps) {
  const { pathname } = useLocation();

  const [openMenu, setOpenMenu] = useState(true);

  const { path, children } = item;

  const { active, isExternalLink } = useActiveLink(path, false);

  useEffect(() => {
    if (openMenu) {
      handleCloseMenu();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const handleOpenMenu = () => {
    if (children) {
      setOpenMenu(true);
    }
  };

  const handleCloseMenu = () => {
    setOpenMenu(false);
  };

  // esse carinha controla quando clica, abre e fecha o menu  ->

  const handleToggleMenu = () => {
    if (children) {
      setOpenMenu(!openMenu);
    }
  };

  return (
    <>
      <NavItem
        item={item}
        isOffset={isOffset}
        active={active}
        open={openMenu}
        isExternalLink={isExternalLink}
        onClick={handleToggleMenu}
      />

      {!!children && openMenu && (
        <Fade in={openMenu} style={{ position: 'relative' }}>
          <div style={{ position: 'relative', display: 'inline-block' }}>
            <StyledMenu elevation={12} onMouseEnter={handleOpenMenu} onMouseLeave={handleCloseMenu}>
              {children.map((list) => (
                <NavSubList
                  key={list.subheader}
                  items={list.items}
                  isDashboard={list.subheader === 'Dashboard'}
                  onClose={handleCloseMenu}
                />
              ))}
            </StyledMenu>
          </div>
        </Fade>
      )}
    </>
  );
}

// ----------------------------------------------------------------------

type NavSubListProps = {
  isDashboard: boolean;
  items: NavItemProps[];
  onClose: VoidFunction;
};

function NavSubList({ items, isDashboard, onClose }: NavSubListProps) {
  const { pathname } = useLocation();

  const isActive = (path: string) => pathname === path;

  return (
    <Stack spacing={2.5} gridColumn="span 1" alignItems="flex-start">
      {items.map((item) => (
        <NavItem
          subItem
          key={item.title}
          item={item}
          active={isActive(item.path)}
          onClick={onClose}
        />
      ))}
    </Stack>
  );
}
