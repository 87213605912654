import { init, track } from 'fbq';

// ------------------------------------------------

const pixel = '1517279539159801';

export const PageViewPixel = () => {
  init(pixel);
  track('PageView');
};

export const PurchasePixels = () => {
  init(pixel);
  track('Purchase');
};

export const LeadPixels = () => {
  init(pixel);
  track('Lead');
};

export const RegistrationPixels = (formSubscribe: number | string) => {
  init(pixel);

  track('CompleteRegistration', {
    value: formSubscribe,
    currency: 'BRL',
  });
};
